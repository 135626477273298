import { feedBacks, goals, meetings, notes, pulse, recognitions, reviews, surveys, tasks } from './moduleNames'

const pageViewRoutes = [
  // { path: '#/pricing', name: 'Pricing' },
  { path: '#/home', name: 'HomePage' },
  { path: '#/home/my-people', name: 'MyPeoplePage' },
  { path: '#/me', name: 'MyPeoplePage' },
  // { path: `#/me/${recognitions}/:mode?`, name: 'RecognitionsPage', hasParams: true },
  // { path: `#/me/${feedBacks}/:mode?`, name: 'FeedbacksPage', hasParams: true },
  { path: `#/me/requests`, name: 'TasksPage' },
  // { path: `#/me/${tasks}/:mode?`, name: 'TasksPage', hasParams: true },
  // { path: `#/me/${meetings}/:mode?`, name: 'MeetingsPage', hasParams: true }, // LEGACY since we manually add page view event for meetings page
  { path: `#/me/${notes}`, name: 'NotesPage' },
  { path: `#/me/${pulse}`, name: 'PulsesPage' },
  // { path: `#/me/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  // { path: `#/me/${reviews}/:mode?`, name: 'ReviewsPage', hasParams: true },
  // { path: `#/me/${surveys}/:mode?`, name: 'SurveysPage', hasParams: true },
  { path: '#/me/oneonones', name: 'OneOnOnesPage' },
  // { path: `#/user/:upn/${tasks}/:mode?`, name: 'TasksPage', hasParams: true },
  // { path: `#/user/:upn/${feedBacks}/:mode?`, name: 'FeedbacksPage', hasParams: true },
  // { path: `#/user/:upn/${recognitions}/:mode?`, name: 'RecognitionsPage', hasParams: true },
  { path: `#/user/:upn/${notes}`, name: 'NotesPage', hasParams: true },
  // { path: `#/user/:upn/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  // { path: `#/user/:upn/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  { path: '#/grouppage/:id?', name: 'GoalsPage', hasParams: true },
  { path: '#/goalshare/:id?/:emails?/:managerUPN?', name: 'GoalsScreenSharePage', hasParams: true },
  { path: `#/profile/:oid/:oid2`, name: 'UserPage' },
  // { path: `#/user/:upn/${reviews}/:mode?`, name: 'ReviewsPage', hasParams: true },
  // { path: `#/user/:upn/${surveys}/:mode?`, name: 'SurveysPage', hasParams: true },
  { path: '#/user/:upn/:feature?', name: 'UserPage', hasParams: true },
  { path: '#/newtaskmessageextension', name: 'NewTaskFormMessageExtension' },
  { path: '#/newtask', name: 'NewTaskForm' },
  { path: '#/newoneonone', name: 'NewOneOnOneForm' },
  { path: '#/newfeedback', name: 'NewFeedbackForm' },
  { path: '#/newfeedbackmessageextension', name: 'NewFeedbackFormMessageExtension' },
  { path: '#/newrecognition', name: 'NewRecognitionForm' },
  { path: '#/newrecognitioncompose', name: 'NewRecognitionFormCompose' },
  { path: '#/newrecognitionmessageextension', name: 'NewRecognitionFormMessageExtension' },
  { path: '#/newgoalmessageextension', name: 'NewGoalFormMessageExtension' },
  // { path: '#/feedback/:id', name: 'FeedbackPage', hasParams: true },
  { path: '#/task/:id', name: 'TaskPage', hasParams: true },
  { path: '#/request/:id', name: 'RequestPage', hasParams: true },
  { path: '#/meeting/:id/:startDate?:/deleted?', name: 'MeetingPage', hasParams: true },
  { path: '#/oneonone/:id', name: 'OneOnOnePage', hasParams: true },
  { path: '#/goal/:id', name: 'GoalPage', hasParams: true },
  // { path: '#/review/:id', name: 'ReviewPage', hasParams: true },
  // { path: '#/survey/:id', name: 'SurveyPage', hasParams: true },
  // { path: '#/360degree/:id', name: '360DegreeFeedbackPage', hasParams: true },
  { path: '#/reviewtoshare/:id', name: 'ReviewsScreenSharePage', hasParams: true },
  { path: '#/getstarted', name: 'LoginPage' },
  { path: '#/search', name: 'SearchPage' },
  { path: '#/feature-requests', name: 'FeatureRequestPage' },
  { path: '#/subscription-thank-you', name: 'SubscriptionThankYouPage' },
  { path: '#/team', name: 'MyPeoplePage' },
  { path: '#/settings', name: 'UserSettingsPage' },
  { path: '#/teamstabconfigure', name: 'TeamsTabConfigurePage' },
  { path: '#/reviewprint', name: 'ReviewPrintPage' },
  { path: '#/demo', name: 'Demo' },
  { path: '#/onboard', name: 'Onboard' },
  { path: '#/feature-suggest', name: 'FeatureSuggest' },
  { path: '#/reviewprint', name: 'ReviewPrintPage' },
  { path: '#/individual-development-plan', name: 'IndividualDevelopmentPlanPage' },
  { path: '#/my-individual-development-plan/:id/:status', name: 'MyIndividualDevelopmentPlanPage' },
  { path: '#/insight/:cycleid', name: 'InsightsPage' },
  { path: '#/start', name: 'GetStartedPage' }
]

const intercomRoutes = [
  { path: '#/pricing', name: 'Pricing' },
  { path: '#/home', name: 'HomePage' },
  { path: '#/home/my-people', name: 'MyPeoplePage' },
  { path: '#/me', name: 'MyPeoplePage' },
  { path: `#/me/${recognitions}/:mode?`, name: 'RecognitionsPage', hasParams: true },
  { path: `#/me/${feedBacks}/:mode?`, name: 'FeedbacksPage', hasParams: true },
  { path: `#/me/requests`, name: 'TasksPage' },
  { path: `#/me/${tasks}/:mode?`, name: 'TasksPage', hasParams: true },
  { path: `#/me/${meetings}/:mode?`, name: 'MeetingsPage', hasParams: true }, // LEGACY since we manually add page view event for meetings page
  { path: `#/me/${notes}`, name: 'NotesPage' },
  { path: `#/me/${pulse}`, name: 'PulsesPage' },
  { path: `#/me/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  { path: `#/me/${reviews}/:mode?`, name: 'ReviewsPage', hasParams: true },
  { path: `#/me/${surveys}/:mode?`, name: 'SurveysPage', hasParams: true },
  { path: '#/me/oneonones', name: 'OneOnOnesPage' },
  { path: `#/user/:upn/${tasks}/:mode?`, name: 'TasksPage', hasParams: true },
  { path: `#/user/:upn/${feedBacks}/:mode?`, name: 'FeedbacksPage', hasParams: true },
  { path: `#/user/:upn/${recognitions}/:mode?`, name: 'RecognitionsPage', hasParams: true },
  { path: `#/user/:upn/${notes}`, name: 'NotesPage', hasParams: true },
  { path: `#/user/:upn/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  { path: `#/user/:upn/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  { path: '#/grouppage/:id?', name: 'GoalsPage', hasParams: true },
  { path: '#/goalshare/:id?/:emails?/:managerUPN?', name: 'GoalsScreenSharePage', hasParams: true },
  { path: `#/profile/:oid/:oid2`, name: 'UserPage' },
  { path: `#/user/:upn/${reviews}/:mode?`, name: 'ReviewsPage', hasParams: true },
  { path: `#/user/:upn/${surveys}/:mode?`, name: 'SurveysPage', hasParams: true },
  { path: '#/user/:upn/:feature?', name: 'UserPage', hasParams: true },
  { path: '#/newtaskmessageextension', name: 'NewTaskFormMessageExtension' },
  { path: '#/newtask', name: 'NewTaskForm' },
  { path: '#/newoneonone', name: 'NewOneOnOneForm' },
  { path: '#/newfeedback', name: 'NewFeedbackForm' },
  { path: '#/newfeedbackmessageextension', name: 'NewFeedbackFormMessageExtension' },
  { path: '#/newrecognition', name: 'NewRecognitionForm' },
  { path: '#/newrecognitioncompose', name: 'NewRecognitionFormCompose' },
  { path: '#/newrecognitionmessageextension', name: 'NewRecognitionFormMessageExtension' },
  { path: '#/newgoalmessageextension', name: 'NewGoalFormMessageExtension' },
  { path: '#/feedback/:id', name: 'FeedbackPage', hasParams: true },
  { path: '#/task/:id', name: 'TaskPage', hasParams: true },
  { path: '#/request/:id', name: 'RequestPage', hasParams: true },
  { path: '#/meeting/:id/:startDate?:/deleted?', name: 'MeetingPage', hasParams: true },
  { path: '#/oneonone/:id', name: 'OneOnOnePage', hasParams: true },
  { path: '#/goal/:id', name: 'GoalPage', hasParams: true },
  { path: '#/review/:id', name: 'ReviewPage', hasParams: true },
  { path: '#/survey/:id', name: 'SurveyPage', hasParams: true },
  { path: '#/360degree/:id', name: '360DegreeFeedbackPage', hasParams: true },
  { path: '#/reviewtoshare/:id', name: 'ReviewsScreenSharePage', hasParams: true },
  { path: '#/getstarted', name: 'LoginPage' },
  { path: '#/search', name: 'SearchPage' },
  { path: '#/feature-requests', name: 'FeatureRequestPage' },
  { path: '#/subscription-thank-you', name: 'SubscriptionThankYouPage' },
  { path: '#/team', name: 'MyPeoplePage' },
  { path: '#/settings', name: 'UserSettingsPage' },
  { path: '#/teamstabconfigure', name: 'TeamsTabConfigurePage' },
  { path: '#/reviewprint', name: 'ReviewPrintPage' },
  { path: '#/demo', name: 'Demo' },
  { path: '#/onboard', name: 'Onboard' },
  { path: '#/feature-suggest', name: 'FeatureSuggest' },
  { path: '#/reviewprint', name: 'ReviewPrintPage' },
  { path: '#/individual-development-plan', name: 'IndividualDevelopmentPlanPage' },
  { path: '#/my-individual-development-plan/:id/:status', name: 'MyIndividualDevelopmentPlanPage' },
  { path: '#/insight/:cycleid', name: 'InsightsPage' },
  { path: '#/start', name: 'GetStartedPage' }
]

const errorEventRoutes = [
  { path: '#/pricing', name: 'Pricing' },
  { path: '#/home', name: 'HomePage' },
  { path: '#/home/my-people', name: 'MyPeoplePage' },
  { path: '#/me', name: 'MyPeoplePage' },
  { path: `#/me/${recognitions}/:mode?`, name: 'RecognitionsPage', hasParams: true },
  { path: `#/me/${feedBacks}/:mode?`, name: 'FeedbacksPage', hasParams: true },
  { path: `#/me/requests`, name: 'TasksPage' },
  { path: `#/me/${tasks}/:mode?`, name: 'TasksPage', hasParams: true },
  { path: `#/me/${meetings}/:mode?`, name: 'MeetingsPage', hasParams: true }, // LEGACY since we manually add page view event for meetings page
  { path: `#/me/${notes}`, name: 'NotesPage' },
  { path: `#/me/${pulse}`, name: 'PulsesPage' },
  { path: `#/me/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  { path: `#/me/${reviews}/:mode?`, name: 'ReviewsPage', hasParams: true },
  { path: `#/me/${surveys}/:mode?`, name: 'SurveysPage', hasParams: true },
  { path: '#/me/oneonones', name: 'OneOnOnesPage' },
  { path: `#/user/:upn/${tasks}/:mode?`, name: 'TasksPage', hasParams: true },
  { path: `#/user/:upn/${feedBacks}/:mode?`, name: 'FeedbacksPage', hasParams: true },
  { path: `#/user/:upn/${recognitions}/:mode?`, name: 'RecognitionsPage', hasParams: true },
  { path: `#/user/:upn/${notes}`, name: 'NotesPage', hasParams: true },
  { path: `#/user/:upn/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  { path: `#/user/:upn/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  { path: '#/grouppage/:id?', name: 'GoalsPage', hasParams: true },
  { path: '#/goalshare/:id?/:emails?/:managerUPN?', name: 'GoalsScreenSharePage', hasParams: true },
  { path: `#/profile/:oid/:oid2`, name: 'UserPage' },
  { path: `#/user/:upn/${reviews}/:mode?`, name: 'ReviewsPage', hasParams: true },
  { path: `#/user/:upn/${surveys}/:mode?`, name: 'SurveysPage', hasParams: true },
  { path: '#/user/:upn/:feature?', name: 'UserPage', hasParams: true },
  { path: '#/newtaskmessageextension', name: 'NewTaskFormMessageExtension' },
  { path: '#/newtask', name: 'NewTaskForm' },
  { path: '#/newoneonone', name: 'NewOneOnOneForm' },
  { path: '#/newfeedback', name: 'NewFeedbackForm' },
  { path: '#/newfeedbackmessageextension', name: 'NewFeedbackFormMessageExtension' },
  { path: '#/newrecognition', name: 'NewRecognitionForm' },
  { path: '#/newrecognitioncompose', name: 'NewRecognitionFormCompose' },
  { path: '#/newrecognitionmessageextension', name: 'NewRecognitionFormMessageExtension' },
  { path: '#/newgoalmessageextension', name: 'NewGoalFormMessageExtension' },
  { path: '#/feedback/:id', name: 'FeedbackPage', hasParams: true },
  { path: '#/task/:id', name: 'TaskPage', hasParams: true },
  { path: '#/request/:id', name: 'RequestPage', hasParams: true },
  { path: '#/meeting/:id/:startDate?:/deleted?', name: 'MeetingPage', hasParams: true },
  { path: '#/oneonone/:id', name: 'OneOnOnePage', hasParams: true },
  { path: '#/goal/:id', name: 'GoalPage', hasParams: true },
  { path: '#/review/:id', name: 'ReviewPage', hasParams: true },
  { path: '#/survey/:id', name: 'SurveyPage', hasParams: true },
  { path: '#/360degree/:id', name: '360DegreeFeedbackPage', hasParams: true },
  { path: '#/reviewtoshare/:id', name: 'ReviewsScreenSharePage', hasParams: true },
  { path: '#/getstarted', name: 'LoginPage' },
  { path: '#/search', name: 'SearchPage' },
  { path: '#/feature-requests', name: 'FeatureRequestPage' },
  { path: '#/subscription-thank-you', name: 'SubscriptionThankYouPage' },
  { path: '#/team', name: 'MyPeoplePage' },
  { path: '#/settings', name: 'UserSettingsPage' },
  { path: '#/teamstabconfigure', name: 'TeamsTabConfigurePage' },
  { path: '#/reviewprint', name: 'ReviewPrintPage' },
  { path: '#/demo', name: 'Demo' },
  { path: '#/onboard', name: 'Onboard' },
  { path: '#/feature-suggest', name: 'FeatureSuggest' },
  { path: '#/reviewprint', name: 'ReviewPrintPage' },
  { path: '#/individual-development-plan', name: 'IndividualDevelopmentPlanPage' },
  { path: '#/my-individual-development-plan/:id/:status', name: 'MyIndividualDevelopmentPlanPage' },
  { path: '#/insight/:cycleid', name: 'InsightsPage' },
  { path: '#/start', name: 'GetStartedPage' }
]

const allRoutes = [
  { path: '#/pricing', name: 'Pricing' },
  { path: '#/start', name: 'GetStartedPage' },
  { path: '#/home', name: 'HomePage' },
  { path: '#/home/my-people', name: 'MyPeoplePage' },
  { path: '#/me', name: 'MyPeoplePage' },
  { path: `#/me/${recognitions}/:mode?`, name: 'RecognitionsPage', hasParams: true },
  { path: `#/me/${feedBacks}/:mode?`, name: 'FeedbacksPage', hasParams: true },
  { path: `#/me/requests`, name: 'TasksPage' },
  { path: `#/me/${tasks}/:mode?`, name: 'TasksPage', hasParams: true },
  { path: `#/me/${meetings}/:mode?`, name: 'MeetingsPage', hasParams: true }, // LEGACY since we manually add page view event for meetings page
  { path: `#/me/${notes}`, name: 'NotesPage' },
  { path: `#/me/${pulse}`, name: 'PulsesPage' },
  { path: `#/me/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  { path: `#/me/${reviews}/:mode?`, name: 'ReviewsPage', hasParams: true },
  { path: `#/me/${surveys}/:mode?`, name: 'SurveysPage', hasParams: true },
  { path: '#/me/oneonones', name: 'OneOnOnesPage' },
  { path: `#/user/:upn/${tasks}/:mode?`, name: 'TasksPage', hasParams: true },
  { path: `#/user/:upn/${feedBacks}/:mode?`, name: 'FeedbacksPage', hasParams: true },
  { path: `#/user/:upn/${recognitions}/:mode?`, name: 'RecognitionsPage', hasParams: true },
  { path: `#/user/:upn/${notes}`, name: 'NotesPage', hasParams: true },
  { path: `#/user/:upn/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  { path: `#/user/:upn/${goals}/:mode?/:id?`, name: 'GoalsPage', hasParams: true },
  { path: '#/grouppage/:id?', name: 'GoalsPage', hasParams: true },
  { path: '#/goalshare/:id?/:emails?/:managerUPN?', name: 'GoalsScreenSharePage', hasParams: true },
  { path: `#/profile/:oid/:oid2`, name: 'UserPage' },
  { path: `#/user/:upn/${reviews}/:mode?`, name: 'ReviewsPage', hasParams: true },
  { path: `#/user/:upn/${surveys}/:mode?`, name: 'SurveysPage', hasParams: true },
  { path: '#/user/:upn/:feature?', name: 'UserPage', hasParams: true },
  { path: '#/newtaskmessageextension', name: 'NewTaskFormMessageExtension' },
  { path: '#/newtask', name: 'NewTaskForm' },
  { path: '#/newoneonone', name: 'NewOneOnOneForm' },
  { path: '#/newfeedback', name: 'NewFeedbackForm' },
  { path: '#/newfeedbackmessageextension', name: 'NewFeedbackFormMessageExtension' },
  { path: '#/newrecognition', name: 'NewRecognitionForm' },
  { path: '#/newrecognitioncompose', name: 'NewRecognitionFormCompose' },
  { path: '#/newrecognitionmessageextension', name: 'NewRecognitionFormMessageExtension' },
  { path: '#/newgoalmessageextension', name: 'NewGoalFormMessageExtension' },
  { path: '#/feedback/:id', name: 'FeedbackPage', hasParams: true },
  { path: '#/task/:id', name: 'TaskPage', hasParams: true },
  { path: '#/request/:id', name: 'RequestPage', hasParams: true },
  { path: '#/meeting/:id/:startDate?:/deleted?', name: 'MeetingPage', hasParams: true },
  { path: '#/oneonone/:id', name: 'OneOnOnePage', hasParams: true },
  { path: '#/goal/:id', name: 'GoalPage', hasParams: true },
  { path: '#/review/:id', name: 'ReviewPage', hasParams: true },
  { path: '#/survey/:id', name: 'SurveyPage', hasParams: true },
  { path: '#/360degree/:id', name: '360DegreeFeedbackPage', hasParams: true },
  { path: '#/reviewtoshare/:id', name: 'ReviewsScreenSharePage', hasParams: true },
  { path: '#/getstarted', name: 'LoginPage' },
  { path: '#/search', name: 'SearchPage' },
  { path: '#/feature-requests', name: 'FeatureRequestPage' },
  { path: '#/subscription-thank-you', name: 'SubscriptionThankYouPage' },
  { path: '#/team', name: 'MyPeoplePage' },
  { path: '#/settings', name: 'UserSettingsPage' },
  { path: '#/teamstabconfigure', name: 'TeamsTabConfigurePage' },
  { path: '#/reviewprint', name: 'ReviewPrintPage' },
  { path: '#/individual-development-plan', name: 'IndividualDevelopmentPlanPage' },
  { path: '#/my-individual-development-plan/:id/:status', name: 'MyIndividualDevelopmentPlanPage' },
  { path: '#/reviewprint', name: 'ReviewPrintPage' },
  { path: '#/demo', name: 'Demo' },
  { path: '#/onboard', name: 'Onboard' },
  { path: '#/feature-suggest', name: 'FeatureSuggest' },
  { path: '#/insight/:cycleid', name: 'InsightsPage' }
]

const notAllVisitedURLRouteNames = ['Pricing', 'Demo', 'Onboard', 'FeatureSuggest']
const lastVisitedURLRoutes = allRoutes.filter(route => !notAllVisitedURLRouteNames.includes(route.name))

const notCachedRouteNames = [
  'pricing',
  'demo',
  'onboard',
  'feature-suggest',
  'feature-requests',
  'profile',
  'feature-requests-extension'
]
export { pageViewRoutes, intercomRoutes, errorEventRoutes, allRoutes, lastVisitedURLRoutes, notCachedRouteNames }
