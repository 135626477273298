import * as React from 'react'
const AllowAccessSvg = () => {
  return (
    <svg width='95' height='32' viewBox='0 0 95 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.2126 12.9445C17.8695 12.9445 19.2126 14.2876 19.2126 15.9445L19.2126 26.1571C19.2126 26.7094 18.7649 27.1571 18.2126 27.1571H14.7874C14.2351 27.1571 13.7874 26.7094 13.7874 26.1571V18.3697L6 18.3697C5.44771 18.3697 5 17.922 5 17.3697L5 13.9445C5 13.3922 5.44772 12.9445 6 12.9445H13.7874L16.2126 12.9445Z'
        fill='#DA335E'
      />
      <rect x='19.0547' y='4' width='7.66667' height='5.11111' rx='1' transform='rotate(90 19.0547 4)' fill='#34343A' />
      <rect
        x='28'
        y='18.0555'
        width='7.66667'
        height='5.11111'
        rx='1'
        transform='rotate(-180 28 18.0555)'
        fill='#34343A'
      />
      <path
        d='M50.97 10.2109C50.6787 9.92971 50.2065 9.92971 49.9152 10.2109C49.6239 10.4921 49.6239 10.9479 49.9152 11.2291L52.3713 13.6H40.7461C40.3342 13.6 40.0003 13.9224 40.0003 14.32C40.0003 14.7176 40.3342 15.04 40.7461 15.04H52.3713H54.6994C55.1967 15.04 54.9907 14.0921 54.6994 13.8109L50.97 10.2109ZM40.2182 16.96C39.9269 17.2412 39.9274 17.9079 40.2187 18.1891L43.9481 21.7891C44.2394 22.0703 44.7117 22.0703 45.003 21.7891C45.2943 21.5079 45.2943 21.0521 45.003 20.7709L42.5469 18.4H54.172C54.5839 18.4 54.9179 18.0776 54.9179 17.68C54.9179 17.2824 54.5839 16.96 54.172 16.96H42.5469C42.2374 16.96 40.2182 16.96 40.2182 16.96Z'
        fill='#CECECE'
      />
      <rect x='63' width='32' height='32' rx='8' fill='white' />
      <path d='M78.337 4H67V15.4958H78.337V4Z' fill='#F25022' />
      <path d='M90.9991 4H79.6621V15.4958H90.9991V4Z' fill='#7FBA00' />
      <path d='M78.337 16.5042H67V28H78.337V16.5042Z' fill='#00A4EF' />
      <path d='M90.9991 16.5042H79.6621V28H90.9991V16.5042Z' fill='#FFB900' />
    </svg>
  )
}

export default AllowAccessSvg
