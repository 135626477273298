import * as moment from 'moment'

const hubspotIdentifyUser = (userData, inTeams) => {
  if (!userData.mail) return
  // we only want to identify admins who are the first to join the tenant
  if (userData.role === 2 && moment(userData.tenant.createdAt).isAfter(moment().subtract(1, 'minute'))) {
    var _hsq = (window._hsq = window._hsq || [])
    _hsq.push([
      'identify',
      {
        email: userData.mail
      }
    ])
    _hsq.push(['setPath', '/#/home'])
    _hsq.push(['trackPageView'])
  }
}

export { hubspotIdentifyUser }
