import * as React from 'react'
import { Button } from '@fluentui/react-northstar'

const ButtonPrimary = ({
  content,
  onClick,
  disabled,
  icon,
  size,
  iconOnly,
  iconPosition,
  style,
  loading,
  id,
  className
}: {
  content?: string
  onClick: (...args: any[]) => void
  disabled?: boolean
  icon?: React.ReactElement
  size?: 'small' | 'medium'
  iconOnly?: boolean
  iconPosition?: 'before' | 'after'
  style?: React.CSSProperties
  loading?: boolean
  id?: string
  className?: string
}) => (
  <Button
    iconOnly={iconOnly}
    size={size}
    icon={icon}
    primary
    content={content}
    onClick={onClick}
    disabled={disabled}
    style={{ minWidth: 'unset', padding: '8px 16px', ...style }}
    iconPosition={iconPosition}
    loading={loading}
    id={id}
    className={className}
  />
)

export default ButtonPrimary
