import * as React from 'react'
import { useTranslation } from 'react-i18next'
import AllowAccessButton from './AllowAccessButton'
import { Icon, Text, TooltipHost } from 'office-ui-fabric-react'
import useStore from '../../../hooks/useStore'

const AllowAccessBanner = () => {
  const { userStore } = useStore()
  const { t } = useTranslation()

  if (!userStore.showAllowAccessBanner) {
    return null
  }

  return (
    <div
      style={{
        background: '#444691',
        width: '100%',
        padding: '11.5px 8px',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        maxHeight: 55,
        minHeight: 55,
        height: 55
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 16,
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            overflow: 'hidden'
          }}
        >
          <Text
            style={{
              color: '#FFF',
              fontSize: 14,
              fontWeight: 600,
              lineHeight: '20px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {t(
              'AllowAccessBanner_toHelpYouConnectWithColleaguesAndAccessInTeamflectWeNeedAFewPermissions',
              'To help you connect with colleagues and use Teamflect seamlessly, we need a few permissions from you'
            )}
          </Text>
          <TooltipHost
            styles={{
              root: {
                height: 20
              }
            }}
            content={t(
              'AllowAccessBanner_thesePermissionsHelpUnlockKeyFunctionalitiesLikeConnectingWithColleaguesPersonalizedRecommendationsAndCalendarAccess',
              'These permissions help unlock key functionalities like connecting with colleagues, personalized recommendations and calendar access.'
            )}
          >
            <Icon iconName='Info' style={{ color: '#FFF' }} />
          </TooltipHost>
        </div>

        <AllowAccessButton
          outlined
          isUserOptionCheckedByDefault
          isCalendarOptionCheckedByDefault
          openFrom='AllowAccessBanner'
        />
      </div>
    </div>
  )
}

export default AllowAccessBanner
