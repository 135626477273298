import * as React from 'react'
import { Button } from '@fluentui/react-northstar'
import '../../styles/button.less'
import cn from 'classnames'

const ButtonSecondary = ({
  content,
  onClick,
  disabled,
  icon,
  size,
  iconOnly,
  iconPosition,
  style,
  loading,
  className
}: {
  content?: string
  onClick?: (...args: any[]) => void
  disabled?: boolean
  icon?: React.ReactElement
  size?: 'small' | 'medium'
  iconOnly?: boolean
  iconPosition?: 'before' | 'after'
  style?: React.CSSProperties
  loading?: boolean
  className?: string
}) => (
  <Button
    iconPosition={iconPosition}
    disabled={disabled}
    iconOnly={iconOnly}
    size={size}
    loading={loading}
    icon={icon}
    content={content}
    onClick={onClick}
    className={cn('button-secondary', { [className]: className }, { disabled: disabled })}
    style={{
      color: disabled ? '#44469150' : '#444691',
      borderColor: disabled ? '#44469150' : '#444691',
      backgroundColor: 'transparent',
      minWidth: 'unset',
      padding: '8px 16px',
      ...style
    }}
  />
)

export default ButtonSecondary
