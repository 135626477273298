import * as React from 'react'
import { useContext } from 'react'
import { AppContext } from '../context/AppContext'


const useStore = () => {
  return useContext(AppContext)
}

export default useStore
