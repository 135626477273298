import { createTheme } from 'office-ui-fabric-react'

const darkTheme = createTheme({
  defaultFontStyle: { fontSize: '12px' },
  fonts: {},
  palette: {
    themePrimary: '#ffffff',
    themeLighterAlt: '#767676',
    themeLighter: '#a6a6a6',
    themeLight: '#c8c8c8',
    themeTertiary: '#d0d0d0',
    themeSecondary: '#dadada',
    themeDarkAlt: '#eaeaea',
    themeDark: '#f4f4f4',
    themeDarker: '#f8f8f8',
    neutralLighterAlt: '#201f1f',
    neutralLighter: '#201f1f',
    neutralLight: '#1e1e1e',
    neutralQuaternaryAlt: '#1c1b1b',
    neutralQuaternary: '#1b1a1a',
    neutralTertiaryAlt: '#1a1919',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#201f1f'
  }
})

const lightTheme = createTheme({
  defaultFontStyle: { fontSize: '12px', fontWeight: 500, color: '#34343a' },
  fonts: {},
  palette: {
    themePrimary: '#5b5fc7',
    themeLighterAlt: '#f7f7fd',
    themeLighter: '#e1e1f6',
    themeLight: '#c8c9ee',
    themeTertiary: '#9698dd',
    themeSecondary: '#6c6fce',
    themeDarkAlt: '#5256b3',
    themeDark: '#464897',
    themeDarker: '#33356f',
    neutralLighterAlt: '#ecebe9',
    neutralLighter: '#e8e7e6',
    neutralLight: '#dedddc',
    neutralQuaternaryAlt: '#cfcecd',
    neutralQuaternary: '#c6c5c4',
    neutralTertiaryAlt: '#bebdbc',
    neutralTertiary: '#cacaca',
    neutralSecondary: '#959595',
    neutralPrimaryAlt: '#646464',
    neutralPrimary: '#313131',
    neutralDark: '#3c3c3c',
    black: '#2c2c2c',
    white: '#f3f2f1'
  }
})

export { darkTheme, lightTheme }
