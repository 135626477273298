import { Button } from '@fluentui/react-northstar'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import useStore from '../../../hooks/useStore'
import { PermissionSelectModal } from '../PermissionSelectModal/PermissionSelectModal'
import { mixpanelButtonClickedEvent } from '../../../helpers/mixPanelHelper'
import { ButtonPrimary } from '../../button'

const AllowAccessButton = ({
  outlined = false,
  openFrom = '',
  isCalendarOptionCheckedByDefault = false,
  isUserOptionCheckedByDefault = false
}) => {
  const { modalStore } = useStore()

  const { t } = useTranslation()

  const handleOpenAllowAccess = () => {
    mixpanelButtonClickedEvent('Allow Access Button', openFrom)
    modalStore.showModal(
      <PermissionSelectModal
        onClose={modalStore.closeModal}
        openFrom={openFrom}
        isCalendarOptionCheckedByDefault={isCalendarOptionCheckedByDefault}
        isUserOptionCheckedByDefault={isUserOptionCheckedByDefault}
      />
    )
  }

  const commonStyle: React.CSSProperties = {
    padding: '8px 12px',
  }

  if (outlined) {
    commonStyle.border = '1px solid #fff'
    commonStyle.backgroundColor = 'transparent'
  }

  return (
    <ButtonPrimary
      style={commonStyle}
      onClick={handleOpenAllowAccess}
      content={t('AllowAccessButton_allowAccessButton', 'Allow access')}
    />
  )
}

export default AllowAccessButton
